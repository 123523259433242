#cards-procedimentos
  +tablet()
    padding 2rem 0
  .copy-card-procedimento
    h2
      @extend .title
      text-align: center
  .wrap-cards
    h3
      font-weight: 500;
      font-size: 1.5rem
      line-height: 40px;
      text-align: center;

    #card-procedimento
      position relative
      #card-procedimento-track
        +tablet()
          padding 3rem 0

        #card-procedimento-list
          +desktop()
            display: flex
            justify-content: center
          .img-procedimento
            width 240px
            height 294px
            margin 0 auto
            +tablet()
              box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);


      .splide__pagination
        position absolute
        bottom -0.5rem
        +tablet()
          bottom 0
        .splide__pagination__page
          background $green
          opacity 0.2
          width 14px
          height 14px

        .splide__pagination__page.is-active
          background $green
          opacity: 1
          border none
          transform: none
