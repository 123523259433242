#sobre-page
  .img-sobre 
    position: relative
    left: -367px;
    height 600px
    +tablet()
      left: -267px;
      height 400px

    +mobile()
      left: -155px;
      height 270px

    
    img
      border-radius: 0 0 100px 0
      object-fit: cover
      height 600px
      width 846px
      object-position: right top;
      +tablet()
        height 400px
      +mobile()
        width 420px
        height 270px

  .copy-sobre
    align-self: center;
    +tablet()
      text-align: center
      margin-top 25px
    
    h3  
      @extend .sub-title
    h1
      @extend .title

    h4
      font-size: 1.375rem
      color $green
      font-weight: 700
      +tablet()
        font-size: 0.875rem

    p
      font-size: 1rem
      color $texto2
      font-weight: 400 
      line-height: 24px
      margin-top 30px
    
    .saiba-mais

      +tablet()
        margin 0 auto

#sobre-vida
  padding-top 40px

  display flex
  align-items: center
  .container
    position relative

  .copy-vida
    align-self: center;
    padding-top 84px
    +tablet()
      text-align: center
      margin-top 25px
      padding-top 0px
    
    h3  
      @extend .sub-title
    h2
      @extend .title
      margin-bottom 15px
    p
      margin-bottom 24px
      line-height: 24px
  .img-vida 
    position: absolute
    right: -360px;

    height 668px
    width 896px
    +tablet()
      position: relative
      width 596px
      
      right: -155px;
      height auto

    +mobile-sm()
      width 305px
      // height 254px  
      right: -55px;


#atualmente
  padding 84px 0
  .copy-atualmente
    text-align: center
    max-width: 947px
    margin 0 auto
    
    h2
      @extend .title
      margin-bottom 15px
    p
      margin-bottom 24px
      line-height: 24px
  .logos-wrap
    justify-content: center
    margin-top 3rem
    gap 148px
    +tablet()
      gap 10px
    .logo-imagem
      width: 222px
      filter: grayscale(100%)
      transition: 0.3s
      +tablet()
        width 130px

      &:hover
        filter: grayscale(0)

      img
        object-fit: cover