#texto-colunas
  .content
    padding-top 32px
    padding-bottom 24px
    +tablet()
      text-align: center
    h2
      @extend .title
    h3
      font-size: 1.875rem
      color $texto2
      font-weight: 700
      line-height: 30px
      margin-bottom 10px

    h4
      font-size: 1rem
      color $darkGreen
      font-weight: 700
      margin-bottom 10px
    p
      @extend .text
      &:not(:last-child)
        margin-bottom 24px
    
    ul
      margin-top 24px
      text-align: left

      li
        color $texto2
        text-align: left

        &:before
          content ''
          display: inline-block
          min-width 15px
          height 15px
          background url('../img/check.png') no-repeat
          margin-right: 10px;