header
  position: sticky;
  top: 0;
  background: #fff;
  z-index 9999

.aviso
  width 100%
  height 25px
  background: $green
  position: sticky
  top: 0

  display: grid
  place-content: center
  transition: 0.5s
  +tablet()
    height 48px

  &.hide
    position: absolute
    height: 0px
    transform: translateY(-200px)
  .copy-aviso
    display flex
    gap 10px
    p 
      font-size: 0.75rem
      color $branco
      line-height: 14px
      // +tablet()
      //   display: inline-block
      a
        color #00395E
        font-weight: 700
        text-decoration: underline
        margin 0 10px
        display: inline-block;
        +tablet()
          display: inline
    button
      border none
      background none
      img 
        width 15px
        height 15px

.top-background
  background-color $green
  .content
    display flex 
    justify-content space-between
    align-content center
  .redes-sociais
    display: flex
    color white
    font-size: 20px
    gap 15px
    align-items center
    +tablet()
      color $green
      margin-top 40px
    p
      +tablet()
        font-size: 0.875rem
        color $texto2
        font-weight: 300
    a
      transition: 0.2s
      &:hover
        color white
        font-size: 22px
  .btn-telefone
   
   
   
    display: flex
    justify-content: center
    align-items: center

    color $branco
    font-size: 1.25rem
    font-weight: 700
    justify-self: end

    grid-column: 3 / 5
    transition: 0.3s
    &:hover
      background $green

      color $branco
      .icon-phone
        color $branco
        transition: 0.3s


    .icon-phone
      color $branco
      font-size: 20px
      margin-right: 10px

#navbar
  display: grid
  grid-template-columns: 1fr
  place-content: center
  height 100px
  box-shadow: 0px 3px 42px 0px rgba(1,58,71,0.14);
  width: 100%
  z-index 9999
  +tablet()
    height 50px
  
  .grid-menu
    display: grid
    grid-template-columns: 1fr 1fr 1fr 53px
    padding-top 0px
    +tablet()
      padding-top 0px
      grid-template-columns: 1fr 1fr

    .redes-sociais
      display: flex
      color $darkGreen
      font-size: 20px
      gap 15px
      +tablet()
        color $green
        margin-top 40px
      p
        +tablet()
          font-size: 0.875rem
          color $texto2
          font-weight: 300
      a
        transition: 0.2s
        &:hover
          color $green
          font-size: 22px


    .logo
      // max-width: 368px
      // height: 40px
      max-width: 176px;
      height: fit-content;
      +tablet()
        height: 18px

  
    .btn-telefone
      width: 210px
      height: 56px
      border: 1px solid $branco
      border-radius: 30px
      background-color: $green
      display: flex
      justify-content: center
      align-items: center

      color $branco
      font-size: 1.25rem
      font-weight: 700
      justify-self: end

      grid-column: 3 / 5
      transition: 0.3s
      &:hover
        background $green

        color $branco
        .icon-phone
          color $branco
          transition: 0.3s


      .icon-phone
        color $branco
        font-size: 20px
        margin-right: 10px

    .menu-wrap
      grid-column: span 3
      #menu
        display flex
        gap 24px
        +desktop()
          gap 18px
          align-items center
          justify-content space-between
          width 1107px
        +tablet()
          display none
        .menu-item 
          font-size: 1rem
          font-weight: 600
          color $texto
          list-style: none

          display flex
          align-items center
          transition: 0.2s
          +tablet()
            display: block
          i 
            font-size: 10px
          a
            padding 0.3rem 0
            &:hover
              color $green
            &.activeMenu
              color $green
              
        .menu-item-has-children
          position relative
          padding: 1rem 0;
          +tablet()
            padding 0
          a
            .opened
              display none
            &:hover
              color $green

            &[aria-expanded='true']
              .opened
                display inline-block
              .closed
                display none
          .sub-item
            a
              padding 0
              color $branco
              +tablet()
                color $texto!important
                padding-left 1rem
              &:hover
                color $branco
                +tablet()
                  color $texto!important
            +tablet()
              color $texto!important
              font-size: 0.875rem
              margin: 6px
          ul
            +desktop()
              display none
      
          &:hover
            color $green
        
            +desktop()
              ul
                display block
                background $green
                position: absolute
                top: 61px;
                padding: 0.8rem 1rem;
                border-radius: 0 0 12px 12px;
                left: -30px;
                width: 185px;
                z-index 9
                li
                  margin-bottom: 0.5rem;   
    .search-bar  
      position relative
      padding 1rem 2rem
      .icon-search
        font-size: 18px
        color $green
        cursor pointer
        transition: 0.3s
        &:hover
          font-size: 22px
      .buscar
        display block
        background $green
        position: absolute
        top: 52px;
        padding: 0.8rem 1rem;
        border-radius: 0 0 12px 12px;
        right: 0;
        width: 230px;
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s
        .form-busca
          position relative
          input
            border none
            border-radius 20px
            padding-left: 30px;
            font-size: 12px
            width: 100%
            height: 30px
          button
            background: none
            border: none
            position: absolute
            left: 10px
            top 5px

      &.activeSearch
        .buscar  
          height: auto;
          visibility: visible;
          opacity: 1;

  +tablet() 
    .grid-menu

      .menu-wrap
        position: absolute
        height: 0
        visibility: hidden
        transform: translateX(-1000px)
        transition: transform 0.2s


        .buscar-mobile
          margin-bottom: 15px
          @extend .form-busca

        &.open
          background #ffff
          width: 100vw
          position: fixed
          visibility: visible
          top 98px
          transform: translateX(0)
          z-index: 9999;
          height: auto;
          left: 0;
          padding-top 40px
          overflow-y: scroll;
          &:after
            content ''
            display: block
            width: 100vw
            height: 100vh;
            background $purple
            opacity: 0.74
            position: fixed
            left: 75vw;
            top 0px
            z-index: 9998;

          #menu
            display: flex
            flex-direction: column
            z-index: 99991;
            gap 0
            margin-bottom 30px
            li
              // color $purple







.hamb
    display block
    width 34px
    height 20px
    position absolute
    top 0
    bottom 0
    right 15px
    margin auto
    z-index 9999
    +tablet()
      justify-self: self-end;
      position relative
      margin 0
      right 0

    +desktop()
      display none

    &:before
      content ""
      width 100%
      height 5px
      background $darkGreen
      box-shadow 0 9px 0 0 $darkGreen,
                  0 18px 0 0 $darkGreen
      display block
      transition .3s all
      border-radius: 200px
    
    &:after
      content ""
      width 5px
      height 7px
      position absolute
      bottom 0
      right 5px
      background none
      transition .3s all
    
    &.open
      display flex
      align-items center

      &:before
        transform rotate(45deg)
        box-shadow unset
        background $darkGreen
      
      &:after
        width 100%
        left 0
        top 0
        bottom 0
        margin auto
        transform rotate(-45deg)
        background $darkGreen
        height 5px
        border-radius: 200px
