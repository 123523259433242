#cards-tratamentos
  padding 40px 0

  .copy-cards-tratamentos
    text-align: center
    h2
      @extend .title
    p
      @extend .text

  .wrap-cards

    #card-tratamentos
      position relative
      .splide__track
        padding 40px 0
        +mobile()
          overflow: visible

        .box-card
          width: 260px;
          height: 180px;
          border-radius: 24px;
          background: #fff;
          box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
          padding 40px
          margin 0 auto
          transition: 0.3s
          +mobile()
            width: 240px;

          h3
            font-weight: 300
            color $darkGreen
            font-size: 1.125rem
          h2
            font-weight: 700
            color $darkGreen
            font-size: 1.5rem
            line-height: 30px


          &.activePage
            background $darkGreen
            h3 
              color $branco
            h2
              color $branco
   
          &:hover
            background $darkGreen
            h3 
              color $branco
            h2
              color $branco           


      .splide__pagination
        position absolute
        bottom -0.5rem
        +tablet()
          bottom 0
        .splide__pagination__page
          background $green
          opacity 0.2
          width 14px
          height 14px

        .splide__pagination__page.is-active
          background $green
          opacity: 1
          border none
          transform: none
