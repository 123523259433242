mobile-sm()
  @media (max-width: 450px)
    {block}

mobile()
  @media (max-width: 767px)
    {block}

tablet()
  @media (max-width: 991.98px)
    {block}

desktop()
  @media (min-width: 992px)
    {block}
    
desktop-xl()
  @media (min-width: 1200px)
    {block}

.container
  max-width: 1140px
  +tablet()
    max-width:500px

  +mobile()
    max-width:290px

h1, h2, h3, h4,h5, h6, p, a, button, input, label, strong, li, span, div, select, option
  font-family: 'Mukta', sans-serif;
strong
  font-weight: 700

@media (min-width: 1000px)
  .hide-on-desktop
    display none !important

@media (min-width: 768px)
  .hide-on-tablet
    display none !important

@media (max-width: 1000px)
  .hide-on-mobile
    display none !important

@media (max-width: 767px)
  .hide-on-mobile-xl
    display none !important

.form-busca
  position relative
  input
    border none
    border-radius 20px
    padding-left: 36px;
    font-size: 12px
    width: 100%
    height: 40px
    border 1px solid #707070
    &::placeholder
      font-size: 1rem
      font-weight: 300
      line-height: 38px
  button
    background: none
    border: none
    position: absolute
    left: 10px
    top 10px
    .icon-search
      font-size: 20px
      color $green

.title 
  font-size: 2.625rem
  color $green
  font-weight: 700
  line-height: 50px
  margin-bottom 15px
  +tablet()
    font-size: 1.625rem
    line-height: 28px
  
.sub-title
  font-size: 1.25rem
  color #959595
  font-weight: 600
  +tablet()
    font-size: 0.75rem

.text
  font-size: 1rem
  color $texto2
  font-weight: 400 
  line-height: 24px

.hide
  opacity: 0!important
  visibility: hidden!important
  height 0!important

.searchwp-live-search-results
  +desktop()
    // left: 1011.5px!important
    width: 230px!important

  border-radius: 10px!important
  min-height: auto!important

  p
    border-bottom: 1px solid $green!important