#form-sidebar
  width: 1000px
  height 432px
  background $darkGreen2
  border-radius: 30px

  padding 30px
  +tablet()
    max-width: 720px
    width: 100%
    height auto
    padding 30px 15px
    margin 0 auto
    border-radius: 0px

  // +mobile()
  //   // width: 500px


  h2
    font-size: 2rem
    color $branco
    font-weight: 700
    +tablet()
      font-size: 1.5rem
      line-height: 28px
      text-align: center
      margin-bottom 22px

  .form-pergunte
    display grid
    grid-template-columns: 1fr 1fr
    gap 10px
    max-width: 560px
    +tablet()
      display: block
      max-width: 100%
    .input-wrapper
      position: relative;
      width 100%
      height auto

      input, textarea
        width 100%
        height 48px
        border-radius: 8px 
        padding-top 10px
        padding-left: 20px 
        border 2px solid rgba(149,149,149,0.24)
        background: #fff
        font-size: 0.875rem
        font-weight: 400
        color $texto2
        +tablet()
          margin-bottom 15px
          height 45px



        &:focus-visible
          outline:$laranja auto 1px;

        &:focus, &:valid
          ~label 
            top: 5px
            left: 20px
            font-size 10px


      textarea
        height 130px
        padding-top 18px
        +tablet()
          height 90px

      &:last-of-type 
        grid-column: span 2

    select
      width 100%
      height 48px
      border-radius: 8px 
      padding-left: 20px 
      border 2px solid rgba(149,149,149,0.24)
      color $texto2
      background: #fff
      +tablet()
        margin-bottom 15px
      // &:last-of-type 
      //   grid-column: span 2

      &::placeholder
        font-size: 0.875rem
        font-weight: 400
        color #959595
        +mobile()
          font-size: 0.8rem
      &:disabled
        color #959595
        font-size: 0.875rem
      option
        color $texto2

      &:invalid
        font-size: 0.875rem
        font-weight: 400
        color #959595

    label 
      position: absolute
      transition: all 0.3s ease
      display: inline-block
      font-size: 0.875rem
      color #959595
      top: 12px
      left: 20px
      +mobile()
        font-size: 0.875rem
    .saiba-mais
      grid-column:  span 2
      margin-left auto
      font-size: 1.125rem
      padding 0
      +tablet()
        margin 0 auto