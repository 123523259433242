#ginecomastia-video
  display: grid
  place-content: center
  height 794px
  background $darkGreen2
  padding 60px 0

  +tablet()
    padding 40px 0
    height: 464px
  @media (max-width: 450px)

    .container
      max-width: 100%
      padding 0

  .saiba-mais
    margin 0 auto
    margin-top 48px
    min-width 240px
    padding 0
  .copy-video
    text-align: center
    margin-bottom 36px
    h2
      @extend .title
      font-size: 2.3rem
    p
      font-size: 2.375rem
      color $branco
      font-weight: 700
      +tablet()
        line-height: 28px
        font-size: 1.625rem
  .iframe
    width 972px
    height: 430px
    display: none

  #player
    +tablet()
      width 420px
      height 375px
      margin 0 auto

    +mobile()
      height 175px
      width 320px
 .start-video 
    position: absolute;
    top: 50%;
    padding: 12px;
    left: 50%;
    opacity: .3;
    width 184px

    transform: translate(-50%, -50%);
    cursor: pointer;
    
    transition: all 0.3s;

    color $branco
    font-size: 1.625rem
    font-weight: 700

    display: flex
    flex-direction: column
    align-items: center

    +mobile()
      font-size: 0.875rem
      opacity: 1;

    img
      width 143px
      margin 0
      +mobile()
        width 45px


    &:hover
      color $branco

      opacity: 1;
      filter: brightness(1)


#ginecomastia-video
  .thumbnail_container
    max-width: 972px;
    // height: 430px;
    overflow: hidden;
    background-color: #000;
    position: relative
    border-radius: 14px
    transition: all 0.3s

    +mobile()
      border-radius: 0

    .thumbnail

      border-radius: 14px
      +tablet()

        border-radius: 0
