#banner
  width 100%
  #banner-slide
    .banner__slide-wrap
      width 100%
      height: 555px

      display: flex
      align-items: center

      background-size cover
      background-position: center
      +mobile()
        background-position: 60% 50%;
        height: 350px

      
      .copy-banner
        width 516px
        +mobile()
          width 100%

        span.title
          font-size: 3.75rem
          color $branco
          line-height: 3.75rem
          font-weight: 700
          +mobile()
            font-size: 1.625rem
            line-height: 28px


          span
            color $green
            display: block
        p
          font-size: 1.175rem
          color $branco
          line-height: 28px
          font-weight: 400
          margin-top 14px
          margin-bottom 20px
          +mobile()
            font-size: 1rem
            line-height: 20px
        .saiba-mais
          width 210px
          height 58px

    .splide__pagination
      flex-direction: column
      right: 100px;
      z-index: 1;
      top: 50%;
      left auto
      bottom auto
      +mobile()
        right: 0px;
        top: 80%;

      .splide__pagination__page
        background $green
        border 1px solid $texto
        width 11px
        height 11px

      .splide__pagination__page.is-active
        background $branco
        border none
        transform: none



#explicacao
  padding-top 54px
  .copy-ginecomastia
    align-self: center;
    +tablet()
      text-align: center
    
    h3  
      @extend .sub-title

    h2
      @extend .title

    p 
      @extend .text
      margin-top 30px
    p+p
      margin-bottom: 50px
    
    .saiba-mais
      margin-bottom 60px
      +tablet()
        margin 0 auto

  .img-ginecomastia
    position: relative
    right: -65px;
    height 534px

    // overflow: hidden
    +tablet()
      height 300px
      margin-top 40px


    +mobile()
      height 254px
      width 426px
      right: -20px;

    
    img
      object-fit: cover
      height 534px
      width 896px
      object-position: top;
      border-radius: 100px 0 0 0 

      +tablet()
        height 300px
        width 696px
        transform: scaleX(-1);
        border-radius: 0 100px 0 0 

      +mobile()
        height 254px
        width 426px

#graus-home
  padding-top 64px
  padding-bottom 100px
  position: relative
  +desktop()
    height: 770px;
  +tablet()
    height: 828px;

  +mobile()
    padding 0
  .img-graus
    position: absolute
    background: url('../img/bg-niveis.png') no-repeat
    background-position: -300px 100%;
    left 0
    top 0
    height 765px
    width 710px 
    z-index -1
    border-radius 0 0 80px 0
    +mobile()
      position: relative
      margin-left: -45px;
      width 305px
      height 325px
      background-size: cover
      background-position: 0px 100%;
  .copy-graus
    text-align: center
    +mobile()
      margin-top 40px
    h2
      @extend .title
    h3
      @extend .sub-title

  .cards-graus
    width: 1085px;
    margin-left: 275px;
    +mobile()
      margin-left 0
      width: auto;


    #slide-graus
      position: relative
      .splide__track
        padding: 4rem 0rem;
        +mobile()
          padding 1rem 0
          padding-bottom: 3rem

      .splide__slide.is-active
        +mobile()
          .card-item
            .hover
              opacity: 1
              visibility: visible
              height: 100px
              margin-bottom 20px
              position relative
            .fixed
              opacity: 0
              visibility hidden
              height 0
              margin 0
      .card-item
        width 308px
        height 340px
        border-radius 30px
        box-shadow: 0px 10px 30px 0px rgba(0,86,92,0.3);
        padding 32px
        text-align: center
        position: relative
        margin 0 auto
        background #fff
        &:hover
          .hover
            opacity: 1
            visibility: visible
            height: 100px
            margin-bottom 20px
            position relative
          .fixed
            opacity: 0
            visibility hidden
            height 0
            margin 0
       

        +mobile()
          width 240px
          padding 20px

        img
          height 100px
          object-fit: contain
          margin-bottom 20px
          transition: all 0.3s

        .hover
          opacity: 0
          visibility hidden
          height 0
          margin 0
    
        h3
          font-size: 1.3rem
          color $darkGreen
          font-weight: 700
        p
          font-size: 1rem
          color $texto2
          font-weight: 400 
          line-height: 24px

        .saiba-mais
          margin 0 auto
          position: absolute
          max-width: 190px
          height 50px
          bottom -30px
          left 50%
          transform: translateX(-50%)
     #slide-graus

      .splide__pagination
        bottom -2.5rem
        left: -315px;
        position: absolute
        +mobile()
          left 0
          bottom -2.5rem
        .splide__pagination__page
          background $green
          opacity 0.2
          width 14px
          height 14px

        .splide__pagination__page.is-active
          background $green
          opacity: 1
          border none
          transform: none
    .splide__arrows
      .splide__arrow
        background #25ABAF
        svg
          fill $branco

#tratamentos-home

  margin-top 40px
  display: flex
  align-items: center
  position relative
  height 764px
  +tablet()
    margin-top 85px
    height: 1350px
    flex-direction: column

  .img-tratamento
    background: url('../img/bg-tratamentos.png') no-repeat
    // background-position: 150% 100%;
    background-size: cover
    width 63% 

    height 764px

    position: absolute
    top 0
    right -260px
    z-index -1
    +tablet()
      height 262px
      order 2
      position: relative
      margin-top 70px
      right -150px
    +mobile()
      right -150px
      width 90%
      right -20px

  .copy-tratamentos
    margin-bottom 40px
    +tablet()
      text-align: center
    h3
      @extend .sub-title
    h2
      @extend .title
  
  .cards-wrap
    display grid
    grid-template-columns: 334px 334px
    justify-content: start
    gap 105px
    +tablet()
      gap 70px
      grid-template-columns: 290px
      justify-content: center

    .card-item
      @extends #graus-home .cards-graus #slide-graus .card-item
      height 410px
      width 334px
      +tablet()
        width 290px
        margin 0 auto
        padding 24px


      img
        height 80px   

#depoimentos
  padding 60px 0
  width: 100%
  max-width: 100%!important;
  +tablet()
    padding 20px 0

  .copy-depoimentos

    margin 0 auto
    text-align: center 
    h3
      @extend .sub-title

    h2 
      @extend .title

  #depoimentos-slide
    margin: 0 auto
    position relative
    max-width: 1060px
  +desktop()
    max-width 1170px
    width 100%
    margin auto


  .depoimentos-container
    padding 2rem 1rem

    .depoimento-box
      width: 290px
      height: 320px
      padding: 4rem 2rem 2rem 2rem
      box-shadow: 0px 10px 30px 0px rgba(0,86,92,0.3);
      position: relative
      margin: 0 auto;
      border-radius 15px
      background-color: #fff

      +mobile()
        width 240px
        height 320px
        padding 0.7rem
        padding-top 4rem

      .icon-google
        max-width: 80px
        height: 80px  
        position: absolute
        top -40px
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 3px 6px 0px rgba(181,181,181,0.16);

        border-radius: 50%

      blockquote
        height 100%
        justify-content space-between

        font-size 1rem
        line-height 1.4
        color #777777
        font-family: 'Mukta', sans-serif;
        

        +mobile()
          justify-content space-between
          text-align: center


        footer
          display grid
          justify-content space-between
          grid-template-columns: 1fr 1fr
          margin-top 5px
          font-family: 'Mukta', sans-serif;
          
          gap 8px
          align-items center

          +mobile()
            grid-template-columns: 1fr 
            justify-content center

          .author
            font-size 18px
            font-weight 700
            color $purpleDark
            font-family: 'Mukta', sans-serif;
            
            grid-column: 1 / 3;
           
            +mobile()
              justify-self: center; 
            // width 50%
          img 
            width: 70px;
            height: 12px  
            justify-self: right;  
            justify-self: self-start;
            grid-column: 1/2;
            grid-row: 2/3;
            object-fit: contain;

            +mobile()
              justify-self: center;  

          p 
            font-size .875rem
            line-height 1.4
            color $cinza
            width 100%
            display block
            +mobile()
              justify-self: center; 
              text-align: center

  .splide__pagination
    
    .splide__pagination__page
      background $green
      opacity 0.2
      width 14px
      height 14px

    .splide__pagination__page.is-active
      background $green
      opacity: 1
      border none
      transform: none

  .splide__track
    padding 2rem 0
    +mobile()
      overflow: visible

  .splide__arrows
    top: 0;
    position: absolute;
    right: 85px;
    .splide__arrow
      background #25ABAF
      svg
        fill $branco
    .splide__arrow--next
      right -2em
      
    .splide__arrow--prev 
      left: -3em;

#banner
  #banner-slide-wrap
    .banner__slide-wrap
      width 100%
      height: 555px

      display: flex
      align-items: center

      background-size cover
      background-position: center
      +mobile()
        background-position: 60% 50%;
        height: 350px

      
      .copy-banner
        width 516px
        +mobile()
          width 100%

        span.title
          font-size: 3.75rem
          color $branco
          line-height: 3.75rem
          font-weight: 700
          +mobile()
            font-size: 1.625rem
            line-height: 28px


          span
            color $green
            display: block
        p
          font-size: 1.175rem
          color $branco
          line-height: 28px
          font-weight: 400
          margin-top 14px
          margin-bottom 20px
          +mobile()
            font-size: 1rem
            line-height: 20px
        .saiba-mais
          width 210px
          height 58px 
#modalInitial 
  z-index 999999999999999999
  background-color rgba(0, 86, 92, 0.87) 
#modalInitial.show 
  +tablet() 
    text-align center
  .modal-dialog
    max-width 1074px  
  .modal-body 
    margin-top 9px
  .modal-content
    height 719px
    border-radius 14px
    border-top 10px solid $green
    +tablet()
      height auto
    .comp-persona
      position absolute  
      position absolute
      left -430px
      height 767px
      object-fit contain
      top -68px
    .btn-close  
      right 19px
      position absolute
      top 15px 
      cursor pointer 
      z-index 9999999

    .content-modal
      z-index: 999;
      position: relative;
      +desktop-xl()
        padding-left 365px
        padding-right 31px
      .title 
        font-size 50px 
        color $green 
        font-weight bold 
        +tablet() 
          font-size 36px 
          line-height 32px
        span
          font-size 40px 
          font-weight bold 
          color $cinzaClaro 
          +tablet()
            font-size 22px 
      p   
        margin-bottom 26px 
        font-size 14px 
        line-height 21px 
      .box-form 
        width 646px
        height 137px
        border-radius 9px
        background #fff
        box-shadow 0px 8px 10px #eaecf2 
        border-top 10px solid $green 
        text-align center
        +tablet()
          width 100%
          height auto
          padding-left 13px
          padding-right 13px 
          padding-bottom 20px 
          margin-bottom 20px
        .txt-obs 
          font-weight bold
          font-size 18px
          line-height 24px
          text-align center
          color $darkGreen2
          max-width 530px 
          display block
          margin 0 auto 
          margin-top 12px 
          +tablet()
            max-width 222px
        form  
          margin-top 10px
          +desktop-xl()
            display flex 
            gap 9px
            justify-content center
          input 
            width 250px 
            height 40px  
            border-radius 10px
            background #fff
            border 2px solid rgba(149, 149, 149, 0.24)
            +tablet()
              width 100%
              margin-bottom 10px

          button 
            width 91px 
            height 40px 
            +tablet()
              margin 0 auto
              width 129px
      .txt-final 
        font-weight bold
        font-size 19px
        line-height 19px
        text-align left
        color $green
        margin-top 16px
        +desktop-xl()
          display flex
          gap 5px 
          justify-content center 
        +tablet()
          text-align center
        *
          font-weight bold
          font-size 19px 
        a  
          color $darkGreen2
          

