.saiba-mais
  border none
  background $laranja
  border-radius: 30px
  width 190px
  height 50px
  padding 0 36px

  display: grid
  place-content: center

  color $branco
  font-size: 1.3rem
  font-weight: 700

  transition: all 0.5s
  +mobile()
    height 45px
    font-size: 1.125rem
    width 240px


  a
    color $branco
  &:hover 
    transform: translateY(-7px)
    color $branco
    span 
      padding-right: 15px
      &:after
        opacity: 1
        right: 0

  &:active 
    transform: translateY(-1px)

  span 
    cursor: pointer
    display: inline-block
    position: relative
    transition: 0.5s
    &:after 
      content: '»'
      position: absolute
      opacity: 0
      top: 0
      right: -15px
      transition: 0.5s