#rodape-desktop
  background: #2D2D2D
  padding 35px 0 0 0
  margin-top 70px

  +mobile()
    margin-top 0
  .sobre
    .logo-footer
      max-width: 202px
    p
      font-weight 400
      color #fff
      font-size 0.875rem
      line-height: 20px

      margin-top 18px
      margin-bottom 36px

    .continue 
      font-size 1rem
      color $green
      font-weight: 600
      text-decoration: underline
      position relative
      // &:hover 
      &:after
        content: '»'
        position: absolute
        opacity: 0
        top: 0
        margin-left: 5px
        transition: 0.5s
        color $green
      &:hover
        &:after
            opacity: 1
      img
        max-width: 16px!important
        height 7px
        margin-left 5px

  .contatos
    text-align: right 
   

    .btn-telefone
      width: 210px
      height: 56px
      border: 1px solid $green
      border-radius: 30px
      
      display: flex
      justify-content: center
      align-items: center

      color $branco
      font-size: 1.25rem
      font-weight: 700
      justify-self: end
      margin-left: auto;
      grid-column: 3 / 5
      transition: 0.3s
      &:hover
        background $green

        color $branco
        .icon-phone
          color $branco
          transition: 0.3s

      .icon-phone
        color $green
        font-size: 20px
        margin-right: 10px
    .email
        font-size: 0.875rem
        color $branco
        font-weight: 300
        display: flex
        align-items: center
        gap 3px
        margin 14px 0
        div
          color $green
          font-size: 14px


    .redes-sociais
      display: flex
      align-items: center
      color $green
      font-size: 15px
      gap 15px
      justify-content: right;
      +tablet()
        color $branco
        margin-top 40px
      p
        font-size: 0.875rem
        color $branco
        font-weight: 300
  .rodape
    padding 1rem 0
    margin-top 54px
    width 100%
    height 54px
    p 
      color $branco
      font-size: 1rem
      font-weight: 400
    .xceller
      justify-content end
      a
        color $branco
        font-weight: 500

      .img-3xceller
        width 77px 
        height 23px

  .mega-menu-rodape
  
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    justify-content: center
    gap: 1rem
    grid-auto-flow: column
    
  
    a
      
      font-size 1.125rem
      &:hover
        color $darkGreen

      
    .itens-menu
      h4
        font-size: 13px
        color $branco
        font-weight: 700
        text-transform: uppercase

      h5
        font-size: 12px
        color $branco
        font-weight: 300
        display: block
        margin-bottom 21px
        span
          display block
          font-style: italic
      h3
        font-weight: 500
        font-size: 15px
        color $green

      .menu-item
        list-style: none

        a
          color #fff
          font-weight: 400
          font-size: 0.81rem
          line-height: 22px




#rodape-mobile
  background: #2D2D2D
  padding 30px 0
  margin-top 40px
  
  .copy-rodape
    text-align: center
    margin-bottom: 15px
    h2
      @extend .title
    h3
      @extend .sub-title
      color $branco

    p 
      color $branco 
      font-weight: 400
      margin 10px 0
  .info-contato
    margin 0 auto
    margin-bottom: 20px
    text-align: center

    &:nth-child(4)

      p, a 
        font-size: 1rem
    h3 
      @extend .title

      display: flex
      align-items: center
      gap 0.3rem
      justify-content: center
      margin-bottom: 6px

      i 
        font-size: 26px
    p, a 
      font-size: 1.125rem
      color $branco
      font-weight: 700
      text-align: center
    h4
      font-size: 1rem
      color $branco
      font-weight: 700
      text-align: center
      text-transform: uppercase
    h5
      font-size: 1rem
      color $branco
      font-weight: 500
      text-align: center
      display: block
      margin-bottom 21px
      span
        display block
        font-style: italic

  .info-medico
    text-align: center
    p
      font-size: 0.875rem
      color $branco
      font-weight: 300     
#bottom-bar
  padding 40px 0
  background: #2D2D2D
  border-top 1px solid #262626

  .xceller
    justify-content center
    p 
      color $branco
      font-size: 1rem
      font-weight: 600
    .img-3xceller
      width 114px 
      height 34px