#intro-page
  margin-top 64px
  +tablet()
    margin-top 40px
  .copy-pagina
    margin 0 auto
    +tablet()
      text-align: center
      margin-bottom 28px
    h1
      @extend .title
      margin-bottom 0
    h3
      @extend .sub-title 
#sobre-int
  margin-bottom:45px;
  .saiba-mais 
    display:none;
