.blog
    padding-top: 48px;
    .bg-teste
        border-radius 17px
        background-repeat no-repeat
        background-size cover
        background-position center 
        margin-bottom 33px 
        padding-top 18px 
        padding-left 27px 
        padding-right 27px 
        margin-top 10px 
        +desktop-xl()
            display flex
            height 90px
            width 101%
            background-image url('../img/banner-teste.png')
        +tablet()
            background-image url('../img/banner-teste-mob.jpg')    
            padding-bottom 27px
        .title 
            color white 
            font-size 26px 
            line-height 28px 
            font-weight 400 
            display block
            +desktop-xl()
                width 387px
            +tablet()
                text-align center
            span        
                color #00395E
                font-weight bold 
        .persona
            width auto
            height 141px
            margin-top -69px 
            margin-right 13px
        .ilustracao 
           
            +desktop-xl()
                width auto
                height 72.5px
                margin-right 31px  
                margin-top -6px
            +tablet()
                width 100%
                height auto
        .txt-final 
            font-size 19px 
            font-weight bold 
            line-height 19px 
            color $green 
            width 70px
            +tablet()
                margin-top 10px
                display block
                width 100%
        .saiba-mais 
            margin-right 20px
            width 166px
            height 40px
            margin-top 10px
            font-size 13px
            +tablet()
                margin-left auto
                margin-right auto

    .border-boxs 
        border-radius 15px
    .container-posts
        .container 
            padding-left 0px 
        .row 
            padding-left 0px 
    
    .slide-topo-blog 
        height: 450px;
        width 100%
        margin-bottom 36px
        border-radius: 15px;
        +tablet()
            height 340px
        img  
            height: 450px;
            width 100%
            object-fit cover 
            +tablet()
                object-position: 5%;
                height 340px

            +mobile-sm()
                height 340px
                object-position: 5%;
    .slick-list 
        border-radius: 15px;


    .box-post
        // width: 345px
        height: 420px
        background:#fff
        box-shadow: 0px 3px 20px 0px rgba(139,139,139,0.25);
        border-radius: 15px
        margin 0 auto
        justify-self: center
        margin-bottom 37px

        +tablet()
            // width 240px
            height: 375px

        .img-blog
            height: 240px
            object-fit: cover;
            object-position: top;
            
            +tablet()
                height 240px
                object-position: left;


        .header_card
            img
                border-radius: 15px 15px 0 0
        .content
            padding: 1rem
            padding-bottom: 2rem
            max-height: 180px
            height: 100%
            margin-top: -40px;
            +tablet()
                padding-bottom: 25px
            a
                &:hover
                    color #2D2D2D
        
            h2 
                font-size 22px
                font-weight: 600
                line-height: 28px

                text-decoration: none
                margin-bottom: 24px
                +tablet()
                    font-size: 1.125rem
                    line-height: 19px
                    margin-top: -28px;
                    margin-bottom: 14px

                
            .data 
                font-size 1rem
                font-weight: 700

                display: flex
                align-items: center
                justify-content: center

                margin-bottom: 18px
                background #F6994D
                width 122px
                height: 32px
                border-radius: 18px
                color $branco
                padding: 0.2rem 0;
            .continue 
                font-size 1rem
                color $green
                font-weight: 600
                text-decoration: underline
                position relative
                &:hover 
                    color $green

    .box-sidebar
        margin-bottom 49px
        overflow: hidden    
        .text
            padding-bottom 20px
            padding-left 21px 
            padding-right 21px
            img
                margin-bottom: 12px
            p
                font-size: 0.875rem
                line-height: 20px

        .bg 

            img 
                width 100%
                margin-bottom 18px

        .logo-header
            max-width: 135px
            margin 0 auto
        .title-sidebar 
            font-size 33px 
            font-weight 900 
            color $darkGreen
            line-height 40px
            
        a 
            color $texto2
            font-size 14px 
            font-weight bold 

        .box-post-visitados 
            margin-top: 15px;
            img 
                width: 62px;
                height: 62px;
                object-fit: cover;
                border-radius: 8px  
       
                
            h4 
                font-size 15px
                font-weight  700 
                margin-left: 12px;
                height: 62px;
                display: flex;
                align-items: center;
        .categories-sidebar 
            // margin-left: 25px;
            margin-top 15px
            li
                display flex
                align-items: center
                a
                    font-weight: 700
                    color #515151
                    line-height: 30px
                &:before
                    content: "\2022";
                    color: $darkGreen
                    font-weight: bold;
                    display: inline-block; 
                    width: 1em;
                 

        .txt-redes
            margin-top 46px
            .redes
                p
                    font-size 14px
                    color $purpleDark
                    font-weight 600 
                      
                a    
                    font-size 14px
                    color $purpleDark
        .redes-footer 
            a 
                i 
                    font-size 20px
                    margin-right 5px
                    margin-left 10px 
                    color $vermelho

                    
    .side-news 
        text-align left !important
        padding-top 26px
        strong 
            font-size 22px
            font-weight 600
            line-height 24px
            span 
                font-weight bold
                color #00395E
                font-size 22px
        .btn-outline-secondary
            &:hover
                background #00395E
                i 
                    color $branco 
        form 
            ::placeholder
                font-weight 500

    .slick-dots 
         
        display flex
        align-items center
        justify-content center
        margin-top -52px
        position relative
        li 
            list-style-type none
            button 
                font-size 0
                background transparent
                padding 5px
                border none
                border-radius 10px
                border 1px solid $branco
                margin-right 6px
        .slick-active 
            button 
                background  $branco !important 
    .slide-topo-blog 
        .bg 
            position relative


        .txt-post-slide 
            position absolute
            margin-top -272px
            z-index 9
            margin-left 56px
            h1 
                color #fff
                font-weight bold
                font-size 40px
                max-width 517px
                margin-top: 31px;
                margin-bottom: 27px;
             
            p 
                color #fff
                max-width: 538px;
    .nav-slider 
        display flex
        position absolute
        margin-top -256px
        width 100% 
        .prev 
            right 42px
            position absolute
            font-size 25px
            cursor pointer
            i
                color #ffffff9e
        .next 
            position absolute
            left 16px
            font-size 25px
            cursor pointer
            i
                color #ffffff9e
        
    #single-post 
        h1  
            font-size 40px
            font-weight 700
            color $texto2
            line-height: 45px
        .container-content 
            .date 
                font-size 1rem
                font-weight: 700

                display: flex
                align-items: center
                justify-content: center

                margin: 18px 0
                background #F6994D
                width 122px
                height: 32px
                border-radius: 18px
                color $branco
                padding: 0.2rem 0;
            ul 
                margin-left: 23px;
            
            strong 
                color $texto2
            h2 
                color $texto2
                font-size 24px
                font-weight 600
                margin 10px 0

            h3 
                color $texto2
                font-size 24px
                font-weight 600
                margin 10px 0
            h4 
                color $texto2
                font-size 24px
                font-weight 600
                margin 10px 0
            h5 
                color $texto2
                font-size 24px
                font-weight 600
                margin 10px 0
            h6 
                color $texto2
                font-size 24px
                font-weight 600
                margin 10px 0
            a
                display: inline
                color $green
            p, ul
                margin-bottom 10px
            
            ul
                list-style: disc;
        .col-banner 
            img 
                height 334px
                width 100% 
                object-fit cover
        .container-content 
            position relative
            padding-bottom: 3rem
          
          
            img 
                width 100%
        .title-artigos-rl 
            font-size 30px
            font-weight 700 
            color $darkGreen
            margin-bottom 23px
        .relacionados 
            img 
                width 100%
                height 240px
            h6 
                font-size 15px
                margin-top 15px
                margin-bottom 15px  
    .contato-none 
        display none 
    .box-sidebar-img 
        padding-top 0px
    footer 
        margin-top 60px 
  
    /* Small only */
    @media screen and (min-width: 320px) and (max-width: 63.9375em) 
        .container-posts
            .container 
                padding 0px 

        .box-post 
            text-align left
            .post-dates
                display  block !important
                p
                    font-weight: 600
            .text 
                h2 
                    font-size 18px
        .mobile-p 
            padding 0px 
        .nav-slider 
            .prev 
                right: 16px
        .slide-topo-blog 
            .txt-post-slide 
                margin-top 0px
                z-index 9
                position relative
                margin-left 10px
                margin-right 10px
                height 232px
                text-align center
                h1  
                    margin-top -233px
                    max-width 320px
                    font-size 16px
            .title-artigos-rl 
                font-size 21px
        #single-post 
            text-align center
            h1 
                font-size 21px
            .container-content 
            
                h2 
                    font-size 17px
                h3 
                    font-size 17px
                h4 
                    font-size 17px
                h5 
                    font-size 17px
                h6 
                    font-size 17px
            .title-artigos-rl 
                font-size 21px
            .slick-dots 
                margin-top -6px
            



    /* Large and up */
    @media screen and (min-width: 64em)
        .sidebar 
            padding 0px
        #single-post 
            .container-content 
                padding-right 85px
        // .box-post
        //     height 435px
        .page-padrao 
            padding-top 40px
            padding-bottom 60px
            .container-content 
                padding-left 85px 
            
        
    .paginacao 
        margin-top: 50px
        margin-bottom: 80px
        display flex
        justify-content center
        // border: 1px solid #cacaca;

        .page-numbers.current 
            color: #fff
            background-color: $green
            border: 1px solid $green;
         

        .page-numbers 
            border: 1px solid #cacaca;
            padding: 15px
            font-weight: bold
            color: #515151   
            &.prev
                color $green
                border-radius 5px 0 0 5px  
            &.next 
                color $green
                border-radius 0 5px 5px 0 
       