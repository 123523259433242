#texto-formulario
  padding 24px 0
  +tablet()
    padding 0
  .container
    +mobile()
      max-width: 100%!important
      padding 0!important
  .wrap-content
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: flex-start
    gap 30px
    +tablet()
      grid-template-columns: 1fr

    .text-content-form
      width: 520px
      +tablet()
        max-width: 520px
        width 100%
        margin 0 auto
        text-align: center
      +mobile()
        max-width: 290px

      h2
        @extend .title
        +tablet()
          margin-bottom 20px
      h3
        font-size: 1.875rem
        color $texto2
        font-weight: 700
        line-height: 30px
        margin-bottom 10px

      h4
        font-size: 1rem
        color $darkGreen
        font-weight: 700
        margin-bottom 10px

      p
        @extend .text
        &:not(:last-child)
          margin-bottom 24px
      
      ul
        margin-top 24px
        li
          color $texto2
          text-align: left

          &:before
            content ''
            display: inline-block
            min-width 15px
            height 15px
            background url('../img/check.png') no-repeat
            margin-right: 10px;