#video
  .container
    +mobile()
      max-width: 100%!important
      padding 0!important

  #player
    margin 0 auto
    border-radius: 14px
    overflow: hidden
    display: none
    +tablet()
      width 420px
      height 375px
      margin 0 auto
      border-radius: 0

    +mobile()
      height 175px
    
 .start-video 
    position: absolute;
    top: 50%;
    padding: 12px;
    left: 50%;
    opacity: .3;
    width 184px

    transform: translate(-50%, -50%);
    cursor: pointer;
    
    transition: all 0.3s;

    color $branco
    font-size: 1.625rem
    font-weight: 700

    display: flex
    flex-direction: column
    align-items: center
    +tablet()
      font-size: 0.875rem

    img
      width 143px
      margin 0
      +tablet()
        width 45px


    &:hover
      color $branco

      opacity: 1;
      filter: brightness(1)


#video
  .thumbnail_container
    max-width: 1140px
    height 500px
    margin 0 auto

    overflow: hidden;
    position: relative
    border-radius: 14px
    transition: all 0.3s
    +tablet()
      // width 520px
      height auto

    +mobile()
      height 175px
      border-radius: 0

    .thumbnail
      object-fit: cover
      border-radius: 14px
      +mobile()
        border-radius: 0
        height 175px

