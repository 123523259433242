#cards-graus
  text-align: center
  padding 2rem 0
  .copy-graus
    @extend .title
  
  .cards-graus-page
    position relative
    .splide__track
      padding 2rem 0
      +desktop()
        padding 25px 0

        #slide-graus-page-list
          display: flex
          justify-content: center
          gap 50px
    .card-item
      width 308px
      height 300px
      border-radius 30px
      box-shadow: 0px 10px 30px 0px rgba(0,86,92,0.3);
      padding 32px
      text-align: center
      position: relative
      margin 0 auto
      background #fff
      +mobile()
        width 240px
        padding 20px

      img
        height 100px
        object-fit: contain
        margin-bottom 20px
      h3
        font-size: 1.3rem
        color $darkGreen
        font-weight: 700
      p
        font-size: 1rem
        color $texto2
        font-weight: 400 
        line-height: 24px

  .splide__pagination
    bottom: -0.5em;

    +mobile()
      left 0
      bottom -0.5rem
    .splide__pagination__page
      background $green
      opacity 0.2
      width 14px
      height 14px

    .splide__pagination__page.is-active
      background $green
      opacity: 1
      border none
      transform: none
