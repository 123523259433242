#blog
  +desktop()
    padding-top 66px
  +tablet()
    padding 40px 0

  .copy-blog
   
    text-align: center
    h3
      @extend .sub-title
    h2
      @extend .title
      text-align center
      margin 0 auto
    p
      margin-top 8px
    .ver-todos
      color $darkGreen
      text-decoration: underline
      font-weight: 700
      font-size: 1rem
      text-align: right

  .blog-container
    max-width: 1140px
    margin 0 auto

    .blog-card
      width: 345px
      height: 420px
      background:#fff
      box-shadow: 0px 3px 20px 0px rgba(139,139,139,0.25);
      border-radius: 15px
      margin 0 auto
      justify-self: center
      +tablet()
        width 240px
        height: 375px

      .img-blog
        height: 240px
        object-fit: cover;
        object-position: top;
        
        +tablet()
          height 240px
          object-position: left;


      .header_card
        img
          border-radius: 15px 15px 0 0
    .content
      padding: 1rem
      padding-bottom: 2rem
      max-height: 180px
      height: 100%
      margin-top: -40px;
      +tablet()
        padding-bottom: 25px
      a
        &:hover
          color #2D2D2D
      
      h2 
        font-size 22px
        font-weight: 600
        line-height: 28px

        text-decoration: none
        margin-bottom: 24px
        +tablet()
          font-size: 1.125rem
          line-height: 19px
          margin-top: -28px;
          margin-bottom: 14px

        
      .data 
        font-size 1rem
        font-weight: 700

        display: flex
        align-items: center
        justify-content: center

        margin-bottom: 18px
        background #F6994D
        width 122px
        height: 32px
        border-radius: 18px
        color $branco
        padding: 0.2rem 0;
      .continue 
        font-size 1rem
        color $green
        font-weight: 600
        text-decoration: underline
        position relative
        // &:hover 
        &:after
          content: '»'
          position: absolute
          opacity: 0
          top: 0
          margin-left: 5px
          transition: 0.5s
          color $green
        &:hover
          color $green

          &:after
            opacity: 1

  .ver-todos
    color $darkGreen
    text-decoration: underline
    font-weight: 700
    font-size: 1rem
    text-align: center
    margin-top 15px

  .splide__track
    padding: 2rem 0;

  .splide__pagination
    position relative
    bottom 0rem
    +tablet()
      bottom 0
    .splide__pagination__page
      background $green
      opacity 0.2
      width 14px
      height 14px

    .splide__pagination__page.is-active
      background $green
      opacity: 1
      border none
      transform: none


