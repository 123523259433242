#cards-causas
  padding-top 48px
  padding-bottom: 80px

  .copy-cards
    text-align: center
    margin-bottom 40px
    h2 
      @extend .title
    p 
      @extend .text
  .cards-wraper
    
    display: flex
    align-items: center
    justify-content: center
    gap 30px
    +tablet()
      flex-wrap: wrap
      gap 50px

    .card-item-box
      width 315px
      height 305px
      border-radius: 18px
      box-shadow: 0px 10px 30px 0px rgba(0,86,92,0.3);
      padding 32px 22px

      display: flex
      flex-direction: column
      justify-content: space-between;

      position: relative
      +tablet()
        min-width 290px


      .copy-card
        text-align: center
        h3
          font-size: 1.3rem
          color $darkGreen
          font-weight: 700
          a
            &:hover
              color $darkGreen
        p
          @extend .text
        
      .saiba-mais
        margin 0 auto
        position: absolute
        bottom -55px
        left:50%; 
        transform:translate(-50%, -50%);
        +tablet()
          width 190px
          height 52px
      
        

