#cards-ginecomastia
  padding-top 90px
  +tablet()
    padding-top 45px
  .copy-cards
    text-align: center
    margin-bottom 50px
    h3 
      @extend .sub-title
    h2 
      @extend .title

  #card-conheca
    +desktop()
      #card-conheca-track
        overflow: visible
        #card-conheca-list
      
          display: flex
          align-items: center
          justify-content: center
          gap 15px
    +tablet()
      #card-conheca-track
        padding: 2rem 0;
        +mobile()
          padding-top 0
    .card-item-box
      width 270px
      height 385px
      border-radius: 18px
      box-shadow: 0px 13px 26px 0px rgba(0,0,0,0.16);
      overflow: hidden
      display: block
      margin: 0 auto;

      img 
        height 183px
      .copy-card
        padding 1rem
 
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 200px;
        h3
          a
            font-size: 1.1rem
            font-weight: 700
            color #2D2D2D
            text-decoration: none

        p
          @extend .text
          color #2D2D2D
        .saiba
          font-size: 1rem
          font-weight: 500
          color $green
          text-decoration: underline
          position relative

          &:after
            content: '»'
            position: absolute
            opacity: 0
            top: 0
            left: 80px
            transition: 0.5s
            color $green
          &:hover
            &:after
              opacity: 1

    .splide__pagination
      position relative
      bottom 0rem
      +tablet()
        bottom 0
    .splide__pagination__page
      background $green
      opacity 0.2
      width 14px
      height 14px

    .splide__pagination__page.is-active
      background $green
      opacity: 1
      border none
      transform: none
