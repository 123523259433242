  
* 
  margin 0 
  padding 0
  box-sizing border-box

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video 
	margin 0
	padding 0
	border 0
	font-size: 100%
	font inherit
	vertical-align: baseline


ul 
  list-style none
  margin 0

a 
  text-decoration none
  display: block;
  color inherit
  

img 
  width 100%
  height auto
  display block

html 
  scroll-behavior smooth

p
  margin 0 

main
  overflow-x: hidden




