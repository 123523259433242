#texto-imagem
  padding-top 24px
  .container
    +mobile()
      max-width: 100%!important
      padding 0!important
  .wrap-content
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    gap 50px
    +tablet()
      grid-template-columns: 1fr
      gap 30px

    .copy-texto-imagem
      width: 530px
      +tablet()
        maxwidth: 520px
        width 100%
        margin 0 auto
        text-align: center
      +mobile()
        max-width: 290px

    h2
      @extend .title
      
      +tablet()
        margin-bottom: 20px
    h3
      font-size: 1.875rem
      color $texto2
      font-weight: 700
      line-height: 30px
      margin-bottom 10px


    h4
      font-size: 1rem
      color $darkGreen
      font-weight: 700
      margin-bottom 10px

    p
      @extend .text
      &:not(:last-child)
        margin-bottom 24px
    strong
      font-weight: 700
    ul
      margin-top 24px
      text-align: left
      li
        color $texto2
        text-align: left

        &:before
          content ''
          display: inline-block
          min-width 15px
          height 15px
          background url('../img/check.png') no-repeat
          margin-right: 10px;


    .img-texto-imagem
      width 896px
      height 520px
      +tablet()
        max-width: 896px
        width 90%
        height auto
      &.order-2
        border-radius: 80px 0 0 80px
        +tablet()
          justify-self: right
      &.order-1
        border-radius: 0 80px 80px 0

      