#pergunte
  background: url('../img/bg-form.png') no-repeat
  background-size: cover;
  width: 100%
  padding: 40px 0
  +desktop()
    padding: 100px 0

  .copy-pergunte
    max-width: 700px
    margin 0 auto
    margin-bottom: 1rem
  
    h2
      @extend .title
      color $branco 
      text-align: center


  .form-pergunte
   
    max-width: 70%
    margin 0 auto
    display grid
    grid-template-columns: 1fr 1fr
    gap 10px
    +tablet()
      display: block
      max-width: 100%

      

    input, select, textarea
      width 100%
      height 50px
      border-radius: 8px 
      padding-left: 30px 

      border none

      font-size: 0.875rem
      font-weight: 400
      color $texto2
      background: #fff
      +tablet()
        margin-bottom 10px

      &::placeholder
        font-size: 0.875rem
        font-weight: 400
        color #959595
      &:disabled
        color #959595

      option
        
        color $texto2
      &:invalid
        font-size: 0.875rem
        font-weight: 400
        color #959595

    textarea
      height 160px
      padding-top 10px
      // grid-row 3 / 5
      grid-column:  1 / 3
      +tablet()
        height 100%

    .saiba-mais
      grid-column:  span 2
      margin 0 auto
      font-size: 1.125rem
      padding 0

