#page-contato
  padding 100px 0
  +tablet()
    padding 40px 0
  .copy-contato
    +tablet()
      max-width: 500px
      margin 0 auto
      margin-bottom 10px
      text-align: center

    h2
      @extend .title

    h3
      @extend .sub-title

    p
      @extend .text

  .card-contato
    margin-top: 20px
    width: 450px
    height 200px
    border-left: 10px solid $green
    border-radius 10px
    box-shadow: 0px 10px 20px 0px #EAECF2

    display: flex;
    flex-direction: column
    justify-content: center;
    align-items: start;

    font-weight: 600
    padding 30px
    gap 5px
    +tablet()
      width: 320px
      margin 0 auto
      padding 10px
      margin-top 15px
      margin-bottom: 40px
      height 522px
      .info-contato
        margin 0 auto
        margin-bottom: 20px
        text-align: center

        &:nth-of-type(3)
          a
            font-size: 1rem
        &:nth-of-type(4)
          p
            font-weight: 400

        h3 
          @extend .title
          display: flex
          align-items: center
          gap 0.3rem
          justify-content: center
          margin-bottom: 6px

          i 
            font-size: 26px
        p, a 
          font-size: 1.125rem
          color $texto
          font-weight: 700
          text-align: center


    p,a
      font-weight: 500
      font-size: 1rem
      display: flex
      align-items: center
      gap 9px
      justify-content: start
      color $texto
      line-height: 24px
      i
        color $green
        font-size: 18px

      img
        width: 20px
        height 20px
    .endereco
      display flex
      i
        margin-top 5px
        color $green
        font-size: 18px
      
      p 
        display inline-block
        span
          display: block
          font-weight: 700
  
    .saiba-mais
      @extend .saiba-mais
      margin 0 auto

  .form-pergunte
    display grid
    grid-template-columns: 1fr 1fr
    gap 10px
    +tablet()
      display: block
      max-width: 100%
    .input-wrapper
      position: relative;
      width 100%
      height auto

      input, textarea
        width 100%
        height 52px
        border-radius: 8px 
        padding-top 10px
        padding-left: 20px 
        border 2px solid rgba(149,149,149,0.24)
        background: #fff;
        font-size: 0.875rem
        font-weight: 400
        color $texto2
        +tablet()
          margin-bottom 15px

        &:focus-visible
          outline:$laranja auto 1px;

        &:focus, &:valid
          ~label 
            top: 5px
            left: 20px
            font-size 10px


      textarea
        height 160px
        padding-top 18px

      &:last-of-type 
        grid-column: span 2

    select
      width 100%
      height 52px
      border-radius: 8px 
      padding-left: 20px 
      border 2px solid rgba(149,149,149,0.24)
      color $texto2
      font-size: 0.875rem
      background: #fff;
      +tablet()
        margin-bottom 15px
      &:last-of-type 
        grid-column: span 2

      &::placeholder
        font-size: 0.875rem
        font-weight: 400
        color #959595
      &:disabled
        color #959595

      option
        color $texto2

      &:invalid
        font-size: 0.875rem

        font-weight: 400
        color #959595

    label 
      position: absolute
      transition: all 0.3s ease
      display: inline-block
      font-size: 0.875rem
      color #959595
      top: 16px
      left: 20px

    .saiba-mais
      grid-column:  span 2
      margin-left auto
      font-size: 1.125rem
      padding 0
      +tablet()
        margin 0 auto

#mapa
  margin-bottom 90px
  text-align: center;
  iframe
    width 1140px