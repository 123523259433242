#faq
  padding 64px 0
  background #F9F9F9

  .copy-faq
    margin-bottom 20px
    h3
      @extend .sub-title
    h2
      @extend .title

  .accordion
    background #F9F9F9
    
    .accordion-item
      background: #F9F9F9
      color #2D2D2D
      border none
      border-bottom: 1px solid #C7C7C7

      &:last-child
        .accordion-button
          border-bottom: none
      .accordion-body
        padding 1rem 0
      .accordion-button
        background: #F9F9F9
        color #2D2D2D
        height 95px
        font-weight: 600
        font-size: 1.125rem
        padding 0
        line-height: 24px

        &:after
          background-image: url('../img/mais.svg')

        &:not(.collapsed) 
          color: #2D2D2D
          background-color: #F9F9F9
          box-shadow: inherit!important
          &:after
            background-image: url('../img/menos.svg')
            transform: rotate(180deg)

  
  .mais-duvidas
    margin-top 32px
    p
      font-size: 1rem
      color $texto2
    a
      font-weight: 700
      color $green